import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useEmailSignup } from '../hooks/useEmailSignup';
import { theme } from '../theme/theme';

const SectionWrapper = styled.div`
  width: 100%;
  height: 690px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: rgba(34, 18, 46, 1);

  ${theme.mediaQueries.tablet} {
    background: white;
    height: auto;
    padding: 0;
    margin: 0;
  }
`;

const MainTitle = styled.h1`
  font-family: ${theme.fonts.primary};
  font-size: 56px;
  font-weight: ${theme.fontWeights.black};
  font-style: italic;
  color: ${theme.colors.white};
  text-align: center;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 2;

  ${theme.mediaQueries.tablet} {
    display: none;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  gap: 240px;

  ${theme.mediaQueries.tablet} {
    flex-direction: column;
    gap: 0;
    padding: 0;
  }
`;

const SideContainer = styled.div`
  width: 456px;
  height: 338px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  ${theme.mediaQueries.tablet} {
    width: 456px;
    height: 338px;
  }
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 456px;
  height: 338px;
  background-image: url('/images/countdown1.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: ${theme.borderRadius.xxl};
  z-index: 1;

  &.right {
    background-image: url('/images/countdown2.png');
  }

  ${theme.mediaQueries.tablet} {
    width: 456px;
    height: 338px;
    background-size: contain;
  }
`;

const Box = styled.div`
  width: 456px;
  height: 338px;
  position: relative;
  padding: 0;
  border-radius: ${theme.borderRadius.xxl};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${theme.spacing.xl};
  overflow: hidden;

  ${theme.mediaQueries.tablet} {
    width: 456px;
    height: 338px;
  }
`;

const Title = styled.h2`
  font-family: ${theme.fonts.primary};
  font-size: 32px;
  font-weight: ${theme.fontWeights.black};
  font-style: italic;
  color: ${theme.colors.black};
  width: 264px;
  line-height: 32px;
  letter-spacing: 0;
  text-align: center;
  height: auto;
`;

const CountdownContent = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
`;

const SignupContent = styled.div`
  position: relative;
  z-index: 2;
  width: 264px;
  min-height: 200px;
  color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0;
  margin-top: -40px;

  ${theme.mediaQueries.tablet} {
    width: 264px;
    height: auto;
    padding: 0px;
  }

  h2 {
    font-family: ${theme.fonts.primary};
    font-size: 32px;
    font-weight: ${theme.fontWeights.black};
    font-style: italic;
    line-height: 32px;
    letter-spacing: 0;
    width: 100%;
    min-height: 40px;
    text-align: center;
    margin-bottom: 0px;

    ${theme.mediaQueries.tablet} {
      font-size: 32px;
      line-height: 1.2;
      min-height: auto;
      margin-bottom: -10px;
      max-width: 200px;
    }
  }

  p {
    width: 100%;
    min-height: 40px;
    text-align: center;
    margin-top: ${theme.spacing.sm};
    margin-bottom: ${theme.spacing.md};
    opacity: 0.9;
    font-size: 16px;
    line-height: 1.4;
    font-family: ${theme.fonts.secondary};

    ${theme.mediaQueries.tablet} {
      min-height: auto;
    }
  }
`;

const CountdownDisplay = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};
  margin-top: -10px;
`;

const CountdownItem = styled.div`
  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.medium};
  text-align: center;
  width: 80px;
  height: 64px;
  box-shadow: ${theme.shadows.medium};
  display: flex;
  align-items: center;
  justify-content: center;

  .number {
    font-family: ${theme.fonts.primary};
    font-size: 40px;
    font-weight: ${theme.fontWeights.black};
    font-style: italic;
    line-height: 40px;
    letter-spacing: -0.02em;
    color: ${theme.colors.primary};
  }
`;

const CountdownLabel = styled.div`
  font-family: ${theme.fonts.secondary};
  font-size: 16px;
  font-weight: ${theme.fontWeights.bold};
  letter-spacing: -0.02em;
  color: ${theme.colors.black};
  margin-top: ${theme.spacing.sm};
  text-align: center;
`;

const ManagerImage = styled.img`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 528px;
  height: 440px;
  z-index: 3;
  object-fit: contain;
  pointer-events: none;

  ${theme.mediaQueries.tablet} {
    display: none;
  }
`;

const SignupEmailForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0;
  width: 100%;
  border-radius: 26px;
  position: relative;
  min-width: 200px;
`;

const SignupEmailInput = styled.input`
  min-width: 120px;
  flex: 1;
  height: 48px;
  padding: 0 1rem;
  border: 1px solid rgba(255, 255, 255, 0.35);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 16px;
  color: white;
  font-size: 16px;
  margin-right: 6px;
  box-sizing: border-box;

  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const SignupSubmitButton = styled.button`
  width: 62px;
  height: 48px;
  border: none;
  border-radius: 16px;
  background: #7FFF00;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  color: ${theme.colors.black};
`;

const StatusMessage = styled.div`
  position: absolute;
  bottom: -25px;
  left: 0;
  color: ${props => props.$type === 'error' ? '#ff4444' : '#7FFF00'};
  font-size: 14px;
`;

const calculateTimeLeft = (targetDate) => {
  const now = new Date();
  if (targetDate <= now) return { months: 0, weeks: 0, days: 0 };

  let years = targetDate.getFullYear() - now.getFullYear();
  let months = targetDate.getMonth() - now.getMonth() + years * 12;
  let days = targetDate.getDate() - now.getDate();

  if (days < 0) {
    months -= 1;
    const prevMonth = new Date(targetDate.getFullYear(), targetDate.getMonth(), 0).getDate();
    days += prevMonth;
  }

  if (months < 0) {
    months = 0;
    days = 0;
  }

  const weeks = Math.floor(days / 7);
  days = days % 7;

  return { months, weeks, days };
};

const getLabel = (value, singular, plural) => {
  return value === 1 ? singular : plural;
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  // background: white;
`;

const MobileView = styled.div`
  display: none;
  width: 100%;
  
  ${theme.mediaQueries.tablet} {
    display: block;
  }
`;

const DesktopView = styled.div`
  display: block;
  
  ${theme.mediaQueries.tablet} {
    display: none;
  }
`;

export const CountdownTimer = () => {
  const { email, setEmail, status, handleSubmit } = useEmailSignup();
  const [timeLeft, setTimeLeft] = useState({ months: 0, weeks: 0, days: 0 });

  useEffect(() => {
    const targetDate = new Date('2025-02-14');
    setTimeLeft(calculateTimeLeft(targetDate));
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 86400000);
    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <MobileView>
        <Container>
          <Box>
            <BackgroundImage />
            <CountdownContent>
              <Title>Superligaen starter om:</Title>
              <CountdownDisplay>
                <div>
                  <CountdownItem>
                    <div className="number">{timeLeft.months}</div>
                  </CountdownItem>
                  <CountdownLabel>
                    {getLabel(timeLeft.months, 'Måned', 'Måneder')}
                  </CountdownLabel>
                </div>
                <div>
                  <CountdownItem>
                    <div className="number">{timeLeft.weeks}</div>
                  </CountdownItem>
                  <CountdownLabel>
                    {getLabel(timeLeft.weeks, 'Uge', 'Uger')}
                  </CountdownLabel>
                </div>
                <div>
                  <CountdownItem>
                    <div className="number">{timeLeft.days}</div>
                  </CountdownItem>
                  <CountdownLabel>
                    {getLabel(timeLeft.days, 'Dag', 'Dage')}
                  </CountdownLabel>
                </div>
              </CountdownDisplay>
            </CountdownContent>
          </Box>
          <Box>
            <BackgroundImage className="right" />
            <SignupContent>
              <h2>Opret dig. Helt gratis!</h2>
              <p>Nedtællingen til forårssæsonen er begyndt - og du kan allerede bygge dit Pick Six hold.</p>
              <SignupEmailForm onSubmit={handleSubmit}>
                <SignupEmailInput 
                  type="email" 
                  placeholder="Skriv din email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <SignupSubmitButton type="submit">Go!</SignupSubmitButton>
                {status.message && (
                  <StatusMessage $type={status.type}>{status.message}</StatusMessage>
                )}
              </SignupEmailForm>
            </SignupContent>
          </Box>
        </Container>
      </MobileView>

      <DesktopView>
        <SectionWrapper>
          <MainTitle>Hvem er dine udvalgte?</MainTitle>
          <ContentContainer>
            <SideContainer>
              <BackgroundImage />
              <CountdownContent>
                <Title>Superligaen starter om:</Title>
                <CountdownDisplay>
                  <div>
                    <CountdownItem>
                      <div className="number">{timeLeft.months}</div>
                    </CountdownItem>
                    <CountdownLabel>
                      {getLabel(timeLeft.months, 'Måned', 'Måneder')}
                    </CountdownLabel>
                  </div>
                  <div>
                    <CountdownItem>
                      <div className="number">{timeLeft.weeks}</div>
                    </CountdownItem>
                    <CountdownLabel>
                      {getLabel(timeLeft.weeks, 'Uge', 'Uger')}
                    </CountdownLabel>
                  </div>
                  <div>
                    <CountdownItem>
                      <div className="number">{timeLeft.days}</div>
                    </CountdownItem>
                    <CountdownLabel>
                      {getLabel(timeLeft.days, 'Dag', 'Dage')}
                    </CountdownLabel>
                  </div>
                </CountdownDisplay>
              </CountdownContent>
            </SideContainer>
            <SideContainer id="signup-section">
              <BackgroundImage className="right" />
              <SignupContent>
                <h2>Opret dig. Helt gratis!</h2>
                <p>Nedtællingen til forårssæsonen er begyndt - og du kan allerede bygge dit Pick Six hold.</p>
                <SignupEmailForm onSubmit={handleSubmit}>
                  <SignupEmailInput 
                    type="email" 
                    placeholder="Skriv din email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <SignupSubmitButton type="submit">Go!</SignupSubmitButton>
                  {status.message && (
                    <StatusMessage $type={status.type}>{status.message}</StatusMessage>
                  )}
                </SignupEmailForm>
              </SignupContent>
            </SideContainer>
          </ContentContainer>
          <ManagerImage src="/images/managers.png" alt="Managers" />
        </SectionWrapper>
      </DesktopView>
    </>
  );
};