import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../assets/icons/close.svg';
import { theme } from '../theme/theme';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${theme.colors.overlay};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;

  ${theme.mediaQueries.mobile} {
    padding: 16px;
    align-items: flex-start;
    padding-top: 40px;
  }
`;

const ModalContent = styled.div`
  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.xl};
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  overflow: hidden;
  position: relative;

  ${theme.mediaQueries.mobile} {
    max-height: 85vh;
    border-radius: ${theme.borderRadius.large};
  }
`;

const ModalHeader = styled.div`
  position: sticky;
  top: 0;
  background: ${theme.colors.white};
  padding: 24px 48px 0;
  z-index: 2;

  ${theme.mediaQueries.mobile} {
    padding: 20px 24px 0;
  }
`;

const ModalBody = styled.div`
  padding: 0px 48px 48px;
  overflow-y: auto;
  max-height: calc(90vh - 160px);

  ${theme.mediaQueries.mobile} {
    padding: 0px 24px 32px;
    max-height: calc(85vh - 140px);
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 32px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
  }

  ${theme.mediaQueries.mobile} {
    top: 16px;
    right: 16px;
  }
`;

const Title = styled.h2`
  font-family: ${theme.fonts.primary};
  font-size: 40px;
  font-weight: ${theme.fontWeights.black};
  font-style: italic;
  color: ${theme.colors.primary};
  margin: 0;
  text-align: left;
`;

const Subtitle = styled.h3`
  font-family: ${theme.fonts.primary};
  font-size: 20px;
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.primary};
  margin: 20px 0 12px;
  text-align: left;

  &:first-of-type {
    margin-top: 0;
  }
`;

const Description = styled.p`
  font-family: ${theme.fonts.secondary};
  font-size: 14px;
  line-height: 1.5;
  color: ${theme.colors.black};
  margin: 0 0 16px;
  text-align: left;
`;

const RulesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
`;

const RuleItem = styled.li`
  font-family: ${theme.fonts.secondary};
  font-size: 14px;
  line-height: 1.4;
  color: ${theme.colors.black};
  padding-left: 20px;
  position: relative;
  margin-bottom: 2px;

  &:before {
    content: '•';
    position: absolute;
    left: 6px;
    color: ${theme.colors.black};
  }
`;

const PointsTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin: 16px 0 24px;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: ${theme.borderRadius.small};
  overflow: hidden;
`;

const TableHeader = styled.th`
  padding: 10px 16px;
  font-family: ${theme.fonts.primary};
  font-size: 14px;
  font-weight: ${theme.fontWeights.semiBold};
  color: ${theme.colors.black};
  text-align: ${props => props.$isPoints ? 'center' : 'left'};
  background: rgba(0, 0, 0, 0.05);
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  &:first-child {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }

  &:last-child {
    width: 80px;
  }
`;

const TableRow = styled.tr`
  &:nth-child(odd) {
    background: rgba(0, 0, 0, 0.02);
  }
`;

const TableCell = styled.td`
  padding: 10px 16px;
  font-family: ${theme.fonts.secondary};
  font-size: 14px;
  color: ${theme.colors.black};
  border: none;
  text-align: ${props => props.$isPoints ? 'center' : 'left'};
  font-weight: ${props => props.$isPoints ? theme.fontWeights.semiBold : theme.fontWeights.regular};
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:first-child {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }

  &:last-child {
    width: 80px;
  }
`;

const Divider = styled.hr`
  border: none;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  margin: 16px -48px;
`;

export const RulesModal = ({ onClose }) => {
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <ModalOverlay onClick={handleOverlayClick}>
      <ModalContent>
        <ModalHeader>
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
          <Title>PICK SIX REGLER</Title>
          <Divider />
        </ModalHeader>
        
        <ModalBody>
          <Subtitle>Bliv fodboldens mesterstrateg! ⚽</Subtitle>
          
          <Description>
            Tag kontrollen som manager i et online fodboldspil, der følger Superligaen fra juli til maj.
            Sammensæt dit hold på 6 spillere (1 målmand, 2 forsvarere, 2 midtbanespillere, 1 angriber) med kun 20 bank-klasser og maks. 2 spillere fra samme klub. Justér din opstilling mellem runderne – men husk deadline 2 timer før første kamp! Points opdateres live baseret på officielle kampdata.
          </Description>
          
          <Description>
            Kan du tage dit hold til tops? Bevis det på banen! ⭐
          </Description>

          <Subtitle>Spil regler</Subtitle>
          <RulesList>
            <RuleItem>En sæson følger en fuld Superliga sæson (juli → Maj)</RuleItem>
            <RuleItem>Spillerunder følger Superligaens spillerunder (Runde 1 → 32)</RuleItem>
            <RuleItem>Hold skal bestå af 6 spillere ved rundestart</RuleItem>
            <RuleItem>Holdopstillingen skal bestå af 1 målmand, 2 forsvarsspillere, 2 midtbanespillere og 1 angriber</RuleItem>
            <RuleItem>Man må maximalt have 2 spillere fra samme klub i sin startopstilling</RuleItem>
            <RuleItem>Hvert hold har 20 bank-klasser at udvælge spillere for</RuleItem>
            <RuleItem>Alle spillere har fået defineret bank-klasser fra 1 til 5 (5 for de formodede bedste spillere)</RuleItem>
            <RuleItem>Bank-klasser defineres og opdateres op til halvsæson start (sommer og vinter)</RuleItem>
            <RuleItem>Det er ikke tilladt at udvælge 6 spillere for mere end de 20 bank-klasser</RuleItem>
            <RuleItem>Det er tilladt at udvælge 6 spillere for mindre end de 20 bank-klasser</RuleItem>
            <RuleItem>Man kan opdatere sin holdopstilling mellem alle spillerunder</RuleItem>
            <RuleItem>Deadline for holdopstillinger er 2 timer før kick-off på første kamp i en runde</RuleItem>
            <RuleItem>Holdopstillinger kan ændres 2 timer efter sidste kamp i en runde</RuleItem>
            <RuleItem>Spilleres points uddeles så vidt muligt "live" eller umiddelbart efter kampafvikling</RuleItem>
            <RuleItem>Points følger officiel database, og kan derfor ændres og tilrettes efter kampe</RuleItem>
          </RulesList>

          <Subtitle>Points</Subtitle>
          <PointsTable>
            <thead>
              <tr>
                <TableHeader>Handling</TableHeader>
                <TableHeader $isPoints>Points</TableHeader>
              </tr>
            </thead>
            <tbody>
              <TableRow>
                <TableCell>Starter inde</TableCell>
                <TableCell $isPoints>1</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Spiller 60+ minutter (ex. ekstra tid)</TableCell>
                <TableCell $isPoints>2</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Mål fra målvogter eller forsvar</TableCell>
                <TableCell $isPoints>5</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Mål fra midtbane</TableCell>
                <TableCell $isPoints>4</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Mål fra angreb</TableCell>
                <TableCell $isPoints>3</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Hattrick</TableCell>
                <TableCell $isPoints>6</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Assist</TableCell>
                <TableCell $isPoints>3</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Clean sheet fra målvogter eller forsvar</TableCell>
                <TableCell $isPoints>3</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Clean sheet fra midtbane eller angreb</TableCell>
                <TableCell $isPoints>1</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Hver 2. redning fra målvogter</TableCell>
                <TableCell $isPoints>1</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Straffesparksredning</TableCell>
                <TableCell $isPoints>4</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Valg til rundens hold</TableCell>
                <TableCell $isPoints>6</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Pr. 2. mål scoret for midtbane eller angreb</TableCell>
                <TableCell $isPoints>1</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Pr. 2. mål imod for målvogter eller forsvar</TableCell>
                <TableCell $isPoints style={{ color: '#CC0025' }}>-1</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Brændt straffespark</TableCell>
                <TableCell $isPoints style={{ color: '#CC0025' }}>-2</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Gult kort</TableCell>
                <TableCell $isPoints style={{ color: '#CC0025' }}>-2</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Rødt kort</TableCell>
                <TableCell $isPoints style={{ color: '#CC0025' }}>-3</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Selvmål</TableCell>
                <TableCell $isPoints style={{ color: '#CC0025' }}>-3</TableCell>
              </TableRow>
            </tbody>
          </PointsTable>
        </ModalBody>
      </ModalContent>
    </ModalOverlay>
  );
}; 