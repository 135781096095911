import React from 'react';
import styled from 'styled-components';
import { theme } from '../../theme/theme';

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: ${theme.spacing.xxl} ${theme.spacing.lg};
  box-sizing: border-box;

  ${theme.mediaQueries.mobile} {
    padding: ${theme.spacing.lg} ${theme.spacing.md};
  }
`;

const Title = styled.h1`
  font-family: ${theme.fonts.primary};
  font-size: 56px;
  font-weight: ${theme.fontWeights.black};
  font-style: italic;
  color: ${theme.colors.textPrimary};
  margin-bottom: ${theme.spacing.md};
  text-align: left;

  ${theme.mediaQueries.mobile} {
    font-size: 40px;
  }
`;

const Section = styled.div`
  margin-bottom: ${theme.spacing.xl};
`;

const SectionTitle = styled.h2`
  font-family: ${theme.fonts.primary};
  font-size: 24px;
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.textPrimary};
  margin: ${theme.spacing.lg} 0 ${theme.spacing.md};
`;

const Text = styled.p`
  font-family: ${theme.fonts.secondary};
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.colors.textSecondary};
  margin-bottom: ${theme.spacing.md};
`;

const List = styled.ul`
  list-style: none;
  padding-left: ${theme.spacing.xl};
  margin: ${theme.spacing.md} 0;
`;

const ListItem = styled.li`
  font-family: ${theme.fonts.secondary};
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.colors.textSecondary};
  margin-bottom: ${theme.spacing.sm};
  position: relative;

  &:before {
    content: '•';
    position: absolute;
    left: -${theme.spacing.lg};
    color: ${theme.colors.textSecondary};
  }
`;

const Footer = styled.footer`
  margin-top: ${theme.spacing.xxl};
  padding-top: ${theme.spacing.xl};
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const Copyright = styled.p`
  font-family: ${theme.fonts.secondary};
  font-size: 14px;
  color: ${theme.colors.textSecondary};
`;

const ContentRights = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <Container>
      <Title>Content Rights Information</Title>

      <Section>
        <SectionTitle>Copyright Notice</SectionTitle>
        <Text>
          © {currentYear} Go Pick Six. All rights reserved. The Go Pick Six application, including all content, features, and functionality, is owned by Go Pick Six and is protected by international copyright, trademark, and other intellectual property laws.
        </Text>

        <SectionTitle>App Content</SectionTitle>
        <Text>
          All content within the Go Pick Six application is either owned by Go Pick Six or used with permission. This includes but is not limited to:
        </Text>
        <List>
          <ListItem>Application code and software</ListItem>
          <ListItem>User interface design</ListItem>
          <ListItem>Graphics, images, and icons</ListItem>
          <ListItem>Text and documentation</ListItem>
          <ListItem>Branding and trademarks</ListItem>
        </List>

        <SectionTitle>Third-Party Content</SectionTitle>
        <Text>
          Go Pick Six uses certain third-party content and services, all of which are properly licensed and used with permission:
        </Text>
        <List>
          <ListItem>Analytics services (Mixpanel)</ListItem>
          <ListItem>Development frameworks and libraries</ListItem>
          <ListItem>Font licenses</ListItem>
        </List>

        <SectionTitle>User-Generated Content</SectionTitle>
        <Text>
          Users retain their rights to any content they create and share through the application. By using our service, users grant Go Pick Six a license to use, store, and display their content in connection with providing and improving our services.
        </Text>

        <SectionTitle>Intellectual Property Rights</SectionTitle>
        <Text>
          The Go Pick Six name, logo, and all related names, logos, product and service names, designs, and slogans are trademarks of Go Pick Six or its affiliates. You must not use such marks without the prior written permission of Go Pick Six.
        </Text>

        <SectionTitle>Content Usage Rights</SectionTitle>
        <Text>
          The application and its content are provided for personal, non-commercial use only. Users may not:
        </Text>
        <List>
          <ListItem>Copy, modify, or distribute the application's content</ListItem>
          <ListItem>Decompile, reverse engineer, or attempt to extract the source code</ListItem>
          <ListItem>Remove any copyright or other proprietary notices</ListItem>
          <ListItem>Transfer the materials to another person or mirror the materials on any other server</ListItem>
        </List>

        <SectionTitle>Contact Information</SectionTitle>
        <Text>
          For questions regarding content rights or to report potential violations, please contact us at hej@picksix.dk.
        </Text>
      </Section>

      <Footer>
        <Copyright>© {currentYear} Go Pick Six. All rights reserved.</Copyright>
      </Footer>
    </Container>
  );
};

export default ContentRights; 