import React from 'react';
import styled from 'styled-components';
import { theme } from '../theme/theme';

const CookieWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
  color: ${theme.colors.white};
  padding: ${theme.spacing.md};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
`;

const CookieText = styled.p`
  margin: 0;
  padding-right: ${theme.spacing.lg};
  font-family: ${theme.fonts.secondary};
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};
`;

const Button = styled.button`
  padding: ${theme.spacing.xs} ${theme.spacing.md};
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-weight: ${theme.fontWeights.bold};
  font-family: ${theme.fonts.primary};
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.9;
  }
`;

const AcceptButton = styled(Button)`
  background: ${theme.colors.secondary};
  color: ${theme.colors.black};
`;

const RejectButton = styled(Button)`
  background: transparent;
  color: ${theme.colors.white};
  border: 1px solid ${theme.colors.white};
`;

export const CookieConsent = ({ onAccept, onReject }) => {
  return (
    <CookieWrapper>
      <CookieText>
        Vi bruger cookies og Google Analytics for at forbedre din oplevelse. Du kan vælge at acceptere eller afvise disse cookies.
      </CookieText>
      <ButtonGroup>
        <RejectButton onClick={onReject}>Afvis</RejectButton>
        <AcceptButton onClick={onAccept}>Acceptér</AcceptButton>
      </ButtonGroup>
    </CookieWrapper>
  );
}; 