import React from 'react';
import styled from 'styled-components';
import { theme } from '../theme/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px 0;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 40px 12px;
  }
`;

const StarImage = styled.img`
  height: 32px;
  width: auto;
  margin-bottom: -32px;
`;

const Title = styled.h2`
  font-family: ${theme.fonts.primary};
  font-size: 56px;
  font-weight: ${theme.fontWeights.black};
  font-style: italic;
  color: ${theme.colors.primary};
  margin-bottom: ${theme.spacing.md};
  line-height: 1.1;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

const Description = styled.p`
  font-family: ${theme.fonts.secondary};
  font-size: 20px;
  line-height: 1.5;
  color: ${theme.colors.textSecondary};
  max-width: 1000px;
  margin: 0 120px;

  @media (max-width: 768px) {
    font-size: 16px;
    margin: 0 24px;
  }
`;

const EyesEmoji = styled.span`
  font-size: 32px;
  vertical-align: middle;
  font-style: normal;
  display: inline-block;
`;

export const UnderConstruction = () => {
  return (
    <Container>
      <StarImage src="/images/stars.png" alt="star" />
      <Title>Vi bygger på noget nyt <EyesEmoji>👀</EyesEmoji></Title>
      <Description>
        Vælg 6 stjernespillere fra runde til runde, kæmp om sæsonens titel eller deltag i spændende tema-turneringer.
        Vi nærmer os lancering, men spillet er ikke færdigt endnu. Læs mere om Pick Six nedenfor og 
        tilmeld dig tidlig adgang i dag og få eksklusivt updates! 🙌
      </Description>
    </Container>
  );
}; 