import { useEffect, useState } from 'react';

const COOKIE_CONSENT_KEY = 'cookieConsent';
const GA_SCRIPT_ID = 'ga-script';
const GA_CONFIG_ID = 'ga-config';
const GA_ID = 'G-JKQEWC7EMP';

export const useCookieConsent = () => {
  const [showCookieConsent, setShowCookieConsent] = useState(true);
  const [hasAnalytics, setHasAnalytics] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem(COOKIE_CONSENT_KEY);
    if (consent) {
      setShowCookieConsent(false);
      if (consent === 'accepted') {
        initializeAnalytics();
      }
    }
  }, []);

  const initializeAnalytics = () => {
    if (document.getElementById(GA_SCRIPT_ID)) {
      return;
    }

    const gaScript = document.createElement('script');
    gaScript.id = GA_SCRIPT_ID;
    gaScript.async = true;
    gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`;
    
    const gaConfig = document.createElement('script');
    gaConfig.id = GA_CONFIG_ID;
    gaConfig.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${GA_ID}', {
        send_page_view: true,
        debug_mode: true
      });
    `;

    document.head.appendChild(gaScript);
    document.head.appendChild(gaConfig);
    setHasAnalytics(true);
    
    // Test pageview after small delay to ensure GA is ready
    setTimeout(() => {
      if (window.gtag) {
        window.gtag('event', 'test_event', {
          'event_category': 'testing',
          'event_label': 'initial_load'
        });
      }
    }, 1000);
  };

  const removeAnalytics = () => {
    const gaScript = document.getElementById(GA_SCRIPT_ID);
    const gaConfig = document.getElementById(GA_CONFIG_ID);
    
    if (gaScript) {
      gaScript.remove();
    }
    
    if (gaConfig) {
      gaConfig.remove();
    }

    // Clear any existing dataLayer
    window.dataLayer = undefined;
    window.gtag = undefined;
    
    setHasAnalytics(false);
  };

  const handleAcceptCookies = () => {
    localStorage.setItem(COOKIE_CONSENT_KEY, 'accepted');
    setShowCookieConsent(false);
    initializeAnalytics();
  };

  const handleRejectCookies = () => {
    localStorage.setItem(COOKIE_CONSENT_KEY, 'rejected');
    setShowCookieConsent(false);
    removeAnalytics();
  };

  const handleShowSettings = () => {
    setShowCookieConsent(true);
  };

  return { 
    showCookieConsent, 
    hasAnalytics, 
    handleAcceptCookies, 
    handleRejectCookies,
    handleShowSettings
  };
}; 