import React from 'react';
import styled from 'styled-components';
import { theme } from '../../theme/theme';

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: ${theme.spacing.xxl} ${theme.spacing.lg};
  box-sizing: border-box;

  ${theme.mediaQueries.mobile} {
    padding: ${theme.spacing.lg} ${theme.spacing.md};
  }
`;

const Title = styled.h1`
  font-family: ${theme.fonts.primary};
  font-size: 56px;
  font-weight: ${theme.fontWeights.black};
  font-style: italic;
  color: ${theme.colors.textPrimary};
  margin-bottom: ${theme.spacing.md};
  text-align: left;

  ${theme.mediaQueries.mobile} {
    font-size: 40px;
  }
`;

const LastUpdated = styled.p`
  font-family: ${theme.fonts.secondary};
  font-size: 16px;
  color: ${theme.colors.textSecondary};
  margin-bottom: ${theme.spacing.xl};
`;

const Section = styled.div`
  margin-bottom: ${theme.spacing.xl};
`;

const SectionTitle = styled.h2`
  font-family: ${theme.fonts.primary};
  font-size: 24px;
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.textPrimary};
  margin: ${theme.spacing.lg} 0 ${theme.spacing.md};
`;

const Text = styled.p`
  font-family: ${theme.fonts.secondary};
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.colors.textSecondary};
  margin-bottom: ${theme.spacing.md};
`;

const List = styled.ul`
  list-style: none;
  padding-left: ${theme.spacing.xl};
  margin: ${theme.spacing.md} 0;
`;

const ListItem = styled.li`
  font-family: ${theme.fonts.secondary};
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.colors.textSecondary};
  margin-bottom: ${theme.spacing.sm};
  position: relative;

  &:before {
    content: '•';
    position: absolute;
    left: -${theme.spacing.lg};
    color: ${theme.colors.textSecondary};
  }
`;

const EmailLink = styled.a`
  color: ${theme.colors.primary};
  text-decoration: none;
  font-weight: ${theme.fontWeights.semiBold};
  
  &:hover {
    text-decoration: underline;
  }
`;

const Footer = styled.footer`
  margin-top: ${theme.spacing.xxl};
  padding-top: ${theme.spacing.xl};
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const Copyright = styled.p`
  font-family: ${theme.fonts.secondary};
  font-size: 14px;
  color: ${theme.colors.textSecondary};
`;

const AppStorePrivacyPolicy = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <Container>
      <Title>Privacy Policy for Go Pick Six</Title>
      <LastUpdated>Last updated: January 30, 2025</LastUpdated>

      <Section>
        <SectionTitle>1. Introduction</SectionTitle>
        <Text>
          Go Pick Six ("we," "us," or "our") respects your privacy. This Privacy Policy explains how we collect, use, and protect your information when you use our mobile application.
        </Text>

        <SectionTitle>2. Data We Collect</SectionTitle>
        <Text>We collect the following types of data:</Text>
        <List>
          <ListItem>Device Information (e.g., device model, OS version)</ListItem>
          <ListItem>Anonymous app-generated identifier</ListItem>
          <ListItem>App Interactions (e.g., screen views, feature usage)</ListItem>
          <ListItem>Coarse Location (approximate location based on IP address)</ListItem>
        </List>
        <Text>
          We do not collect personal information such as your name, email, or precise location. All data collection is first-party and used solely within our app.
        </Text>

        <SectionTitle>3. How We Use Your Data</SectionTitle>
        <Text>We use the collected data exclusively for:</Text>
        <List>
          <ListItem>First-party app analytics to understand how our features are used</ListItem>
          <ListItem>Product improvements and performance optimization</ListItem>
          <ListItem>Enhancing user experience based on interaction patterns</ListItem>
        </List>
        <Text>We do not use your data for advertising purposes or share it with data brokers.</Text>

        <SectionTitle>4. Third-Party Services</SectionTitle>
        <Text>
          We use Mixpanel as our analytics provider, but all data collected remains first-party. This means:
        </Text>
        <List>
          <ListItem>Data is collected only within our app</ListItem>
          <ListItem>We don't combine this data with third-party data</ListItem>
          <ListItem>We don't use the data for cross-app tracking or advertising</ListItem>
          <ListItem>Analytics are used solely to understand and improve our app experience</ListItem>
        </List>

        <SectionTitle>5. Data Sharing</SectionTitle>
        <Text>
          We do not sell, share, or combine your data with third parties. Your anonymous usage data stays within our app ecosystem and is used exclusively for understanding and improving our service.
        </Text>

        <SectionTitle>6. User Rights</SectionTitle>
        <Text>
          Our app collects anonymous analytics data to help us improve the app experience. This first-party data collection is integral to how our app functions and helps us deliver a better product. The data we collect is non-personal and used solely for app functionality and performance improvements. If you have questions about our data practices, you can contact us at <EmailLink href="mailto:hej@picksix.dk">hej@picksix.dk</EmailLink>.
        </Text>

        <SectionTitle>7. Changes to This Policy</SectionTitle>
        <Text>
          We may update this Privacy Policy from time to time. Changes will be reflected with a new "Last Updated" date.
        </Text>

        <SectionTitle>8. Contact Us</SectionTitle>
        <Text>
          For any privacy concerns, please contact us at:
        </Text>
        <Text>
          📧 Email: <EmailLink href="mailto:hej@picksix.dk">hej@picksix.dk</EmailLink>
        </Text>
      </Section>

      <Footer>
        <Copyright>© {currentYear} Go Pick Six. All rights reserved.</Copyright>
      </Footer>
    </Container>
  );
};

export default AppStorePrivacyPolicy; 