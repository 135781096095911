import React from 'react';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom';
import AppStorePrivacyPolicy from './components/legal/AppStorePrivacyPolicy';
import ContentRights from './components/legal/ContentRights';
import PrivacyPolicy from './components/legal/PrivacyPolicy';
import MainPage from './components/MainPage';
import NotFound from './components/NotFound';
import { AuthProvider } from './context/AuthContext';

// Configure future flags
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      {/* <Route path="/auth" element={<Auth />} /> */}
      {/* <Route path="/signout" element={<SignOutRoute />} /> */}
      <Route path="/" element={<MainPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/app-privacy-policy" element={<AppStorePrivacyPolicy />} />
      <Route path="/content-rights" element={<ContentRights />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  ),
  {
    future: {
      v7_startTransition: true,
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true
    }
  }
);

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;
