import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from '../theme/theme';
import { RulesModal } from './RulesModal';

const FAQSection = styled.section`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: ${theme.spacing.xxl} ${theme.spacing.lg};
  box-sizing: border-box;
  text-align: center;

  ${theme.mediaQueries.mobile} {
    padding: ${theme.spacing.lg} ${theme.spacing.md};
  }
`;

const Title = styled.h2`
  font-family: ${theme.fonts.primary};
  font-size: 56px;
  font-weight: ${theme.fontWeights.black};
  font-style: italic;
  color: ${theme.colors.primary};
  margin-bottom: ${theme.spacing.xxl};
  text-align: center;
`;

const FAQList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.sm};
  text-align: left;
`;

const FAQItem = styled.div`
  background: #F8F8F8;
  border: 2px solid rgba(0, 0, 0, 0.075);
  border-radius: ${theme.borderRadius.xl};
  overflow: hidden;
  transition: all 0.2s ease;

  &:hover {
    background: ${theme.colors.white};
    box-shadow: 0 0 0 6px rgba(0, 0, 0, 0.05);
  }
`;

const Question = styled.button`
  width: 100%;
  padding: ${theme.spacing.lg} ${theme.spacing.xl};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  font-family: ${theme.fonts.primary};
  font-size: 16px;
  color: ${theme.colors.black};
  font-weight: ${theme.fontWeights.bold};

  ${theme.mediaQueries.mobile} {
    padding: ${theme.spacing.md} ${theme.spacing.lg};
  }
`;

const QuestionText = styled.span`
  margin-right: 60px;

  ${theme.mediaQueries.mobile} {
    margin-right: ${theme.spacing.lg};
  }
`;

const PlusIcon = styled.span`
  width: 20px;
  height: 20px;
  position: relative;
  flex-shrink: 0;
  
  &::before,
  &::after {
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    transition: all 0.2s ease;
  }

  &::before {
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    transform: translateY(-50%) ${props => props.$isOpen ? 'rotate(45deg)' : 'rotate(0)'};
  }

  &::after {
    left: 50%;
    top: 0;
    height: 100%;
    width: 2px;
    transform: translateX(-50%) ${props => props.$isOpen ? 'rotate(45deg)' : 'rotate(0)'};
    opacity: ${props => props.$isOpen ? '1' : '1'};
  }
`;

const Answer = styled.div`
  padding: 0 ${theme.spacing.xl};
  margin-right: 60px;
  margin-bottom: ${props => props.$isOpen ? theme.spacing.lg : '0'};
  font-family: ${theme.fonts.secondary};
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.colors.textSecondary};
  opacity: ${props => props.$isOpen ? 1 : 0};
  height: ${props => props.$isOpen ? 'auto' : '0'};
  overflow: hidden;
  visibility: ${props => props.$isOpen ? 'visible' : 'hidden'};
  transition: all 0.2s ease;
  white-space: pre-line;

  ${theme.mediaQueries.mobile} {
    padding: 0 ${theme.spacing.lg};
    margin-right: ${theme.spacing.lg};
    margin-bottom: ${props => props.$isOpen ? theme.spacing.md : '0'};
  }
`;

const GreenLink = styled.a`
  color: #00B628;
  text-decoration: none;
  font-weight: 700;
  display: block;
  margin-top: 16px;
  
  &:hover {
    text-decoration: underline;
  }
`;

export const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [isRulesModalOpen, setIsRulesModalOpen] = useState(false);

  const faqs = [
    {
      question: `Hvordan opdateres spillerklasser (priser) undervejs i sæsonen?`,
      answer: `Spillerklasser opdateres ved slutningen af hvert transfervindue. En uafhængig jury vurderer spillernes klasse baseret på parametre som fx nuværende niveau, potentiale, forventet spilletid, popularitet mm. Det sikrer, at spillet forbliver dynamisk og afspejler den aktuelle sæson!`
    },
    {
      question: `Hvorfor er det lige præcis 6 spillere man skal vælge?`,
      answer: `Vi har moderniseret Pick Six med et nyt format på 6 spillere for at skabe en mere intens og engagerende spiloplevelse. Dette antal giver den perfekte balance mellem dybde og tilgængelighed.

Det betyder, at både de dedikerede managers, der elsker at dykke ned i statistikker og bruge timevis på at finde den optimale opstilling, og de mere spontane spillere, der vælger deres hold på farten, kan få en sjov og meningsfuld oplevelse.

For at holde spillet sjovt og spændende hele sæsonen igennem, vil vi løbende introducere tema-runder, månedlige konkurrencer, sæsonforudsigelser og meget mere. På den måde forbliver Pick Six underholdende og udfordrende, uanset hvordan du vælger at spille det.`
    },
    {
      question: `Hvilke regler gælder for pointgivning, og hvordan scorer spillerne point?`,
      answer: `I Pick Six scorer dine spillere point i hver runde - både plus og minus, afhængigt af deres præstationer. Husk at vælge dit hold senest 2 timer før første kamp i runden, og glæd dig til at se pointene tikke ind kort efter kampene er spillet.`
    },
    {
      question: `Hvor ofte kan jeg skifte mine spillere ud?`,
      answer: `Du kan frit skifte alle dine spillere mellem hver runde - så ofte du vil, hele sæsonen! Bare husk at sammensætte dit hold med 1 målmand, 2 forsvarere, 2 midtbanespillere og 1 angriber, og max 2 spillere fra samme klub.`
    },
    {
      question: `Er der en begrænsning på antallet af spillere fra hver klub, jeg kan vælge?`,
      answer: `Ja, du kan max vælge 2 spillere fra samme klub til dit Pick Six-hold. Med seks spillere pr. runde og et loft på 20 klasse points, gælder det om at balancere stjernespillere med skjulte perler. Husk også at sætte dit hold senest 2 timer før første kamp i runden - så er du klar til at tage konkurrencen op!`,
      link: {
        text: 'Se alle regler',
        url: '#rules'
      }
    },
    {
      question: `Hvilke præmier kan man vinde?`,
      answer: `Lige nu er der ingen præmier at vinde, da Pick Six stadig er i BETA og første version er under udvikling. Men vi arbejder på nogle fede præmier i samarbejde med relevante fodboldpartnere! Indtil da - kan du spille om æren og titlen som Superligaens bedste Mandagstræner – enten for runden eller hele sæsonen!`
    },
    {
      question: `Kan jeg støtte Pick Six økonomisk?`,
      answer: `Ikke endnu - Pick Six er stadig i BETA, så økonomisk støtte er ikke muligt lige nu. Den bedste hjælp? Del din feedback og spred ordet om dette nye, sjove krydderi til Superligaen. Din opbakning betyder alt!`
    }
  ];

  const handleRulesClick = (e) => {
    e.preventDefault();
    setIsRulesModalOpen(true);
  };

  return (
    <FAQSection>
      <Title>Spørgsmål</Title>
      <FAQList>
        {faqs.map((faq, index) => (
          <FAQItem key={index}>
            <Question onClick={() => setOpenIndex(openIndex === index ? null : index)}>
              <QuestionText>{faq.question}</QuestionText>
              <PlusIcon $isOpen={openIndex === index} />
            </Question>
            <Answer $isOpen={openIndex === index}>
              {faq.answer}
              {faq.link && (
                <>
                  {' '}
                  <GreenLink href={faq.link.url} onClick={handleRulesClick}>{faq.link.text}</GreenLink>
                </>
              )}
            </Answer>
          </FAQItem>
        ))}
      </FAQList>
      {isRulesModalOpen && <RulesModal onClose={() => setIsRulesModalOpen(false)} />}
    </FAQSection>
  );
};