import React from 'react';
import styled from 'styled-components';
import { useCookieConsent } from '../hooks/useCookieConsent';
import { useImageError } from '../hooks/useImageError';
import { ContentBoxes } from './ContentBoxes';
import { CookieConsent } from './CookieConsent';
import { CountdownTimer } from './CountdownSection';
import { FAQ } from './FAQ';
import { Footer } from './Footer';
import { Header } from './Header';
import { Hero } from './Hero';
import { UnderConstruction } from './UnderConstruction';
import { VideoSection } from './VideoSection';

const PageWrapper = styled.div`
  min-height: 100vh;
  background: rgba(247, 244, 251, 1);
  overflow-x: hidden;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Section = styled.div`
  margin-bottom: ${props => {
    switch (props.$type) {
      case 'hero': return '-10px';
      case 'construction': return '-20px';
      case 'content': return '60px';
      case 'video': return '-30px';
      case 'faq': return '20px';
      case 'countdown': return '0px';
      default: return '120px';
    }
  }};
`;

function MainPage() {
  const { showCookieConsent, handleAcceptCookies, handleRejectCookies, handleShowSettings } = useCookieConsent();
  const { imageErrors, handleImageError } = useImageError();

  return (
    <PageWrapper>
      <Header onImageError={handleImageError} imageErrors={imageErrors} />
      <Section $type="hero">
        <Hero onImageError={handleImageError} imageErrors={imageErrors} />
      </Section>
      <Section $type="construction">
        <UnderConstruction />
      </Section>
      <Section $type="content">
        <ContentBoxes />
      </Section>
      <Section $type="video">
        <VideoSection />
      </Section>
      <Section $type="faq">
        <FAQ />
      </Section>
      <Section $type="countdown">
        <CountdownTimer />
      </Section>
      <Footer onShowCookieSettings={!showCookieConsent ? handleShowSettings : undefined} />
      {showCookieConsent && (
        <CookieConsent 
          onAccept={handleAcceptCookies} 
          onReject={handleRejectCookies} 
        />
      )}
    </PageWrapper>
  );
}

export default MainPage;